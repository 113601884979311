





















import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/components/misc/Spinner.vue";

@Component({
  components: {
    Spinner,
  },
})
export default class NetworkLoadingBlock extends Vue {
  get networkLoading() {
    // return true
    return true;
  }
}
